import React from 'react'
import { Flex, Heading, Button } from '@workwhile/ui'
import { CalendarSearch } from 'lucide-react'
import { useCalendarViewContextStateValue } from '../context/CalendarViewProvider'
import { format } from 'date-fns'
import { CalendarControls } from './CalendarControls'

export const CalendarHeader = () => {
  const {
    state: { currentWeekStart },
  } = useCalendarViewContextStateValue()

  return (
    <Flex justifyContent="space-between">
      <Flex alignItems="center">
        <Heading level={3} fontWeight="400">
          {format(currentWeekStart, 'MMMM, yyyy')}
        </Heading>
        <Button
          variant="text"
          ml={2}
          onClick={() => alert('Sorry, this feature is not implemented yet.')}
        >
          <CalendarSearch size={20} />
        </Button>
      </Flex>
      <Flex alignItems="center" style={{ gap: '1rem' }}>
        <CalendarControls />
      </Flex>
    </Flex>
  )
}
