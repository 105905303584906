import React from 'react'
import { Box, Text } from '@workwhile/ui'
import { OrientationShiftCell } from './OrientationShiftCell'
import { WorkShiftCell } from './WorkShiftCell'
import { CalendarViewContextState } from '../../context'

type ShiftRowProps = {
  shiftsByDate: CalendarViewContextState['state']['shiftsByDate']
}
export const ShiftRow = ({ shiftsByDate }: ShiftRowProps) => {
  return (
    <tr>
      <Box as="th" scope="row" verticalAlign="middle">
        <Text fontSize={16}>Shifts</Text>
      </Box>
      {shiftsByDate.map((data) => {
        if (!data.orientationShifts && !data.workShifts) {
          return <Box as="td" p={4} key={`shifts-${data.date}-empty-cell`} />
        }

        return (
          <Box as="td" key={`shifts-wrapper-${data.date}`}>
            {data.orientationShifts?.data.map((shift) => (
              <OrientationShiftCell
                key={`shifts-orientation-${shift.data.id}`}
                shift={shift}
              />
            ))}
            {data.workShifts?.data.map((shift) => (
              <WorkShiftCell
                key={`shifts-work-${shift.data.id}`}
                shift={shift}
              />
            ))}
          </Box>
        )
      })}
    </tr>
  )
}
