import React from 'react'
import { getLocationTimezone } from 'lib/time'
import { formatInTimeZone } from 'date-fns-tz'
import { BaseShiftCell } from './BaseShiftCell'
import { OrientationShiftScheduleResource } from 'pages/AssignmentDetailPage/types'

type OrientationShiftCellProps = {
  shift: OrientationShiftScheduleResource['data'][number]
}

export const OrientationShiftCell = ({ shift }: OrientationShiftCellProps) => {
  const timezone = getLocationTimezone(shift.data.location)
  const startTime = formatInTimeZone(shift.data.startsAt, timezone, 'h:mm a')
  const endTime = formatInTimeZone(shift.data.endsAt, timezone, 'h:mm a z')

  return (
    <BaseShiftCell label="Orientation" value={`${startTime} to ${endTime}`} />
  )
}
