import React from 'react'
import { BaseShiftCell } from './BaseShiftCell'
import { getLocationTimezone } from 'lib/time'
import { formatInTimeZone } from 'date-fns-tz'
import { WorkShiftScheduleResource } from 'pages/AssignmentDetailPage/types'

type WorkShiftCellProps = {
  shift: WorkShiftScheduleResource['data'][number]
}

export const WorkShiftCell = ({ shift }: WorkShiftCellProps) => {
  const timezone = getLocationTimezone(shift.data.location)
  const startTime = formatInTimeZone(shift.data.startsAt, timezone, 'h:mm a')
  const endTime = formatInTimeZone(shift.data.endsAt, timezone, 'h:mm a z')

  return <BaseShiftCell label="Work" value={`${startTime} to ${endTime}`} />
}
