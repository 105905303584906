import React from 'react'
import { CalendarView, CalendarViewProvider } from './Calendar'

export const ScheduleView = () => {
  return (
    <CalendarViewProvider>
      <CalendarView />
    </CalendarViewProvider>
  )
}
