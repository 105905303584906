import React from 'react'
import { Button } from '@workwhile/ui'
import {
  LucideChevronLeft,
  LucideChevronRight,
  CalendarCheck,
} from 'lucide-react'
import { useCalendarViewContextStateValue } from '../context/CalendarViewProvider'

export const CalendarControls = () => {
  const { actions } = useCalendarViewContextStateValue()

  return (
    <>
      <Button
        variant="text"
        onClick={actions.goToPreviousWeek}
        mr={2}
        aria-label="Previous Week"
        backgroundColor="transparent"
      >
        <LucideChevronLeft size={20} />
      </Button>
      <Button
        variant="text"
        onClick={actions.goToToday}
        mr={2}
        aria-label="Today"
      >
        <CalendarCheck size={20} />
      </Button>
      <Button
        variant="text"
        onClick={actions.goToNextWeek}
        aria-label="Next Week"
      >
        <LucideChevronRight size={20} />
      </Button>
    </>
  )
}
