import { eachDayOfInterval, format } from 'date-fns'
import {
  OrientationShiftScheduleResource,
  WorkShiftScheduleResource,
} from 'pages/AssignmentDetailPage/types'
import { User } from 'typings/common_defs'

type GetAssignmentShiftsResponse = {
  shifts: {
    orientation: {
      [day: string]: OrientationShiftScheduleResource
    }
    work: {
      [day: string]: WorkShiftScheduleResource
    }
  }
}

type GetAssignmentShiftsRequest = {
  assignmentId: number
  startDate: Date
  endDate: Date
}

const createFakeWorker = (): User => {
  return {
    id: Math.floor(Math.random() * 500000 + 100000),
    name: `Worker ${Math.random().toString(36).substring(2, 15)}`,
  }
}

const createFakeShift = (
  startsAt: Date,
  endsAt: Date,
  numWorkers: number
): GetAssignmentShiftsResponse['shifts']['orientation'][string]['data'][number]['data'] => {
  const workers = Array.from({ length: numWorkers }, createFakeWorker)

  return {
    id: Math.floor(Math.random() * 500000 + 100000),
    startsAt: startsAt.toISOString(),
    endsAt: endsAt.toISOString(),
    payRate: Math.floor(Math.random() * 100 + 10).toString(),
    locationless: false,
    shiftBonuses: [],
    position: {
      id: Math.floor(Math.random() * 5000 + 1000),
      name: `Position ${Math.random().toString(36).substring(2, 15)}`,
    },
    location: {
      id: Math.floor(Math.random() * 5000 + 1000),
      name: `Location ${Math.random().toString(36).substring(2, 15)}`,
      address: {
        city: `City ${Math.random().toString(36).substring(2, 15)}`,
        state: `State ${Math.random().toString(36).substring(2, 15)}`,
        street: `Street ${Math.random().toString(36).substring(2, 15)}`,
        zip: Math.floor(Math.random() * 100000 + 10000),
        lat: Math.floor(Math.random() * 100000 + 10000).toString(),
        long: Math.floor(Math.random() * 100000 + 10000).toString(),
        timezone: 'America/Los_Angeles',
      },
    },
    rosters: [
      {
        id: Math.floor(Math.random() * 5000 + 1000),
        name: `Roster ${Math.random().toString(36).substring(2, 15)}`,
        workers,
      },
    ],
  }
}

const createFakeOrientationShift = (
  weekStartAt: Date,
  weekEndAt: Date
): GetAssignmentShiftsResponse['shifts']['orientation'] => {
  const days = eachDayOfInterval({
    start: weekStartAt,
    end: weekEndAt,
  })

  const data: GetAssignmentShiftsResponse['shifts']['orientation'][string]['data'] =
    days.map((day) => {
      const startTime = new Date(day)
      startTime.setHours(9, 0, 0) // 9:00 AM
      const endTime = new Date(day)
      endTime.setHours(10, 0, 0) // 10:00 AM
      const data = createFakeShift(startTime, endTime, 1)
      return {
        type: 'orientation',
        data,
      }
    })

  const result: GetAssignmentShiftsResponse['shifts']['orientation'] = {}
  data.forEach((day) => {
    if (day.data?.startsAt) {
      const dayString = format(new Date(day.data.startsAt), 'yyyy-MM-dd')
      result[dayString] = {
        type: 'orientation-shifts',
        data: [day],
      }
    }
  })
  return result
}

const createFakeWorkShift = (
  weekStartAt: Date,
  weekEndAt: Date
): GetAssignmentShiftsResponse['shifts']['work'] => {
  const days = eachDayOfInterval({
    start: weekStartAt,
    end: weekEndAt,
  })

  const data: GetAssignmentShiftsResponse['shifts']['work'][string]['data'] =
    days.map((day) => {
      const startTime = new Date(day)
      startTime.setHours(10, 0, 0)
      const endTime = new Date(day)
      endTime.setHours(15, 30, 0)
      const data = createFakeShift(startTime, endTime, 1)
      return {
        type: 'work',
        data,
      }
    })

  const result: GetAssignmentShiftsResponse['shifts']['work'] = {}
  data.forEach((day) => {
    if (day.data?.startsAt) {
      const dayString = format(new Date(day.data.startsAt), 'yyyy-MM-dd')
      result[dayString] = {
        type: 'work-shifts',
        data: [day],
      }
    }
  })
  return result
}

export const getAssignmentShifts = async ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  assignmentId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  startDate,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endDate,
}: GetAssignmentShiftsRequest): Promise<GetAssignmentShiftsResponse> => {
  // GQL call with assignment id, start and end date
  const sampleAssignmentShiftsResponse: GetAssignmentShiftsResponse = {
    shifts: {
      orientation: {
        ...createFakeOrientationShift(
          new Date('2024-10-28T12:00:00'),
          new Date('2024-10-28T12:00:00')
        ),
        ...createFakeOrientationShift(
          new Date('2024-11-11T12:00:00'),
          new Date('2024-11-11T12:00:00')
        ),
        ...createFakeOrientationShift(
          new Date('2024-11-13T12:00:00'),
          new Date('2024-11-14T12:00:00')
        ),
        ...createFakeOrientationShift(
          new Date('2024-11-20T12:00:00'),
          new Date('2024-11-22T12:00:00')
        ),
      },
      work: {
        ...createFakeWorkShift(
          new Date('2024-10-28T12:00:00'),
          new Date('2024-10-28T12:00:00')
        ),
        ...createFakeWorkShift(
          new Date('2024-11-11T12:00:00'),
          new Date('2024-11-11T12:00:00')
        ),
        ...createFakeWorkShift(
          new Date('2024-11-13T12:00:00'),
          new Date('2024-11-14T12:00:00')
        ),
        ...createFakeWorkShift(
          new Date('2024-11-20T12:00:00'),
          new Date('2024-11-22T12:00:00')
        ),
      },
    },
  }

  return new Promise<GetAssignmentShiftsResponse>((resolve) => {
    setTimeout(() => {
      resolve(sampleAssignmentShiftsResponse)
    }, 2000)
  })
}
