import React from 'react'
import { Box, Text, useAppTheme } from '@workwhile/ui'

type BaseShiftCellProps = {
  label: string
  value: string
}

export const BaseShiftCell = ({ label, value }: BaseShiftCellProps) => {
  const { colors, radii } = useAppTheme()
  return (
    <Box
      border="1px solid"
      borderColor={colors.primary100}
      borderRadius={radii.small}
      m={1}
      p={2}
      style={{
        cursor: 'pointer',
      }}
    >
      <Text fontSize={1} color="lightText">
        {label}
      </Text>
      <Text fontSize={2} color={colors.primary300}>
        {value}
      </Text>
    </Box>
  )
}
