import { useQuery } from '@tanstack/react-query'
import { getAssignmentShifts } from 'api/assignment/getAssignmentShifts'
import { assignmentShiftsKey } from './keys'

export const useAssignmentShiftsQuery = (
  request: Parameters<typeof getAssignmentShifts>[0]
) => {
  return useQuery({
    queryKey: assignmentShiftsKey(request.assignmentId, request.startDate),
    queryFn: () => getAssignmentShifts(request),
  })
}
